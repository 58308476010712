export enum AppSideNavigationTypes {
  Dashboard = 'Dashboard',
  Applications = 'Applications',
  CreateApplication = 'Create application',
  AllApplications = 'All applications',
  TasksAndScheduling = 'Tasks and scheduling',
  Reporting = 'Reporting',
  Teams = 'Teams',
  UsersAndPermissions = 'Roles and permissions',
}
