import { z } from 'zod';
import toString from 'lodash/toString';
import get from 'lodash/get';
import { AssessmentUtil } from 'models/Assessment.model';
import toNumber from 'lodash/toNumber';

export const checklistItemSchema = z.object({
  id: z.string(),
  order: z.number(),
  title: z.string(),
  valueType: z.string(),
  description: z.string(),
  guidanceNotes: z.string(),
  referenceValue: z.number(),
  calculationType: z.string(),
  assessmentResult: z.object({
    status: z.string(),
    statusReason: z.string(),
    measurementValue: z.number(),
  }),
  compliantDescription: z.string(),
  calculationDefinition: z.string(),
  nonCompliantDescription: z.string(),
  notApplicableDescription: z.string(),
  referenceValueDescription: z.string(),
});

export type IChecklistItemEntity = z.infer<typeof checklistItemSchema>;

export const ChecklistItemUtil = {
  parseChecklistItem(json: any): IChecklistItemEntity {
    const checklistItem = {
      id: toString(get(json, 'id')),
      order: toNumber(get(json, 'order')),
      title: toString(get(json, 'title')),
      valueType: toString(get(json, 'valueType')),
      description: toString(get(json, 'description')),
      guidanceNotes: toString(get(json, 'guidanceNotes')),
      referenceValue: toNumber(get(json, 'guidanceNotes')),
      calculationType: toString(get(json, 'calculationType')),
      assessmentResult: AssessmentUtil.parseAssessment(get(json, 'assessmentResult')),
      compliantDescription: toString(get(json, 'compliantDescription')),
      calculationDefinition: toString(get(json, 'calculationDefinition')),
      nonCompliantDescription: toString(get(json, 'nonCompliantDescription')),
      notApplicableDescription: toString(get(json, 'notApplicableDescription')),
      referenceValueDescription: toString(get(json, 'referenceValueDescription')),
    };
    return checklistItem;
  },
  parseChecklistItems(json: any[]): IChecklistItemEntity[] {
    const checklistItems: IChecklistItemEntity[] = [];
    for (const checklist of json) {
      this.parseChecklistItem(checklist);
    }
    return checklistItems;
  },
};
