export enum ApplicationSortCriteria {
  ProcessingDays = 'processingDays',
  ApplicationReference = 'applicationReference',
  Address = 'fullAddress',
  Description = 'description',
  Notification = 'notification',
  Referrals = 'referrals',
  ComplianceChecklists = 'complianceChecklists',
  SiteVisits = 'siteVisits',
  RFIs = 'rfis',
}
