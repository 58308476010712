import { DeepPartial } from 'ts-essentials';
import { Theme } from '@mui/material';

import createPalette from '@mui/material/styles/createPalette';
import { objectivePalette } from 'styles/objectivePalette.ts';
import { flexGap } from 'styles/flexGap.ts';
import { createBreakpoints } from '@mui/system';
import { transition } from 'styles/transition';

const standardFontFamily = [
  'Noto Sans',
  '-apple-system',
  'BlinkMacSystemFont',
  'Arial',
  'Helvetica',
  'sans-serif',
].join(',');

const standardTypography = {
  fontFamily: standardFontFamily,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1.4,
};

const themeOverride: DeepPartial<Theme> = {
  palette: createPalette({
    objective: objectivePalette,
    primary: objectivePalette.light.primary,
    secondary: objectivePalette.light.secondary,
    error: objectivePalette.light.error,
    text: objectivePalette.light.text,
    background: objectivePalette.light.background,
    divider: objectivePalette.light.divider,
  }),

  breakpoints: createBreakpoints({
    values: {
      xs: 0,
      sm: 768, // iPad portrait
      md: 1024, // iPad landscape
      lg: 1280, // laptop (mackbook)
      xl: 1440, // desktop
      xxl: 1600, // desktop
      xxxl: 1920, // desktop (design)
    },
  }),

  mixins: {
    flexGap: (gap: number | string) => flexGap(gap),
    transition: (timeInSec?: number) => transition(timeInSec),
  },
  typography: {
    // Font family - based on ODL
    fontFamily: standardFontFamily,
    // Normalize typography - separate styles from semantic
    h1: {
      fontFamily: standardFontFamily,
      fontSize: '24px',
      fontWeight: 600,
      color: objectivePalette.light.text.primary,
    },
    h2: {
      fontFamily: standardFontFamily,
      fontSize: '18px',
      fontWeight: 600,
      color: objectivePalette.light.text.primary,
    },
    h3: {
      fontFamily: standardFontFamily,
      fontSize: '16px',
      fontWeight: 600,
      color: objectivePalette.light.text.primary,
    },
    h4: {
      fontFamily: standardFontFamily,
    },
    h5: {
      fontFamily: standardFontFamily,
    },
    h6: {
      fontFamily: standardFontFamily,
    },
    subtitle1: standardTypography,
    subtitle2: standardTypography,
    body1: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 400,
      color: objectivePalette.light.text.secondary,
    },
    body2: standardTypography,
    htmlFontSize: 16,
    error: {
      color: objectivePalette.light.error.main,
      fontSize: '1rem',
    },
    label: {
      fontWeight: '500',
    },
    italic: {
      fontStyle: 'italic',
    },
    link: {
      color: objectivePalette.light.chip.blue.main,
    },
  },
};

export default themeOverride as Partial<Theme>;
