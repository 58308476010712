import React from 'react';
import { Table, TableProps } from '@mui/material';

type Props = TableProps & {
  children: React.ReactNode;
};

const ODLTable: React.FC<Props> = ({ children, ...otherProps }) => {
  return <Table {...otherProps}>{children}</Table>;
};

export default ODLTable;
