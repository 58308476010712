import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { AddRoleFormValues, IRoleEntity, UpdateRoleFormValues } from 'models/Role.model';
import { RootState } from 'store/types';
import { serviceContainer } from 'services/serviceContainer';
import { USE_MOCK_BACKEND } from 'constants/configs';
import toNumber from 'lodash/toNumber';
import { createDeepEqualSelector } from 'store/utils.ts';

// Entity Adapter
const entityAdapter = createEntityAdapter<IRoleEntity>({
  sortComparer: (a, b) => (USE_MOCK_BACKEND ? toNumber(a.id) - toNumber(b.id) : a.name.localeCompare(b.name)),
});

// Actions
const upsertRoles = createAction<IRoleEntity[]>('domainData/role/upsertRoles');
const upsertRole = createAction<IRoleEntity>('domainData/role/upsertRole');

// Thunks
export const fetchRoles = createAsyncThunk('domainData/role/fetchRoles', async (_, thunkAPI) => {
  const rolesService = serviceContainer.cradle.roleService;
  const { roles } = await rolesService.fetchRoles();
  thunkAPI.dispatch(upsertRoles(roles));
  return roles;
});

export const fetchRoleById = createAsyncThunk('domainData/role/fetchRole', async (roleId: string, thunkAPI) => {
  const rolesService = serviceContainer.cradle.roleService;
  const { role } = await rolesService.fetchRoleById(roleId);
  thunkAPI.dispatch(upsertRole(role));
  return role;
});

export const addNewRole = createAsyncThunk('domainData/role/addRole', async (args: AddRoleFormValues, thunkAPI) => {
  const rolesService = serviceContainer.cradle.roleService;
  const { id } = await rolesService.addNewRole(args);
  thunkAPI.dispatch(fetchRoleById(id));
  return { id };
});

export const updateRole = createAsyncThunk(
  'domainData/role/updateRole',
  async (args: UpdateRoleFormValues, thunkAPI) => {
    const rolesService = serviceContainer.cradle.roleService;
    const role = await rolesService.updateRole(args);
    thunkAPI.dispatch(upsertRole(role));
    return role;
  },
);

export const deleteRoleById = createAsyncThunk('domainData/role/deleteRole', async (roleId: string, thunkAPI) => {
  const rolesService = serviceContainer.cradle.roleService;
  await rolesService.deleteRole(roleId);
  return roleId;
});

// Reducer
const initialState = entityAdapter.getInitialState();

export const roleReducer = createReducer<typeof initialState>(initialState, (builder) => {
  builder
    .addCase(upsertRoles, entityAdapter.upsertMany)
    .addCase(upsertRole, entityAdapter.upsertOne)
    .addCase(deleteRoleById.fulfilled, entityAdapter.removeOne);
});

// Selectors
export const {
  selectById: selectRoleEntityById,
  selectIds: selectRoleEntityIds,
  selectEntities: selectRoleEntities,
  selectAll: selectAllRoleEntities,
  selectTotal: selectTotalRoleEntities,
} = entityAdapter.getSelectors((state: RootState) => state.domainData.role);

export const selectRoleByName = createDeepEqualSelector(
  [selectAllRoleEntities, (state: RootState, roleName: string) => roleName],
  (roles, roleName) => {
    return roles.find((role) => role.name === roleName);
  },
);
