// flexGap.ts

/**
 * Detect if `gap` is supported for flex in current browser.
 * Note: External variables cache results to avoid re-calculation.
 * Source: https://ishadeed.com/article/flexbox-gap/
 */
let _detectedFlexGapSupported = false;
let _isFlexGapSupported = false;

const checkFlexGapSupported = (): boolean => {
  if (_detectedFlexGapSupported) {
    return _isFlexGapSupported;
  }

  // Create flex container with row-gap set
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  // Create two elements inside it
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // Append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
  flex?.parentNode?.removeChild(flex);

  _detectedFlexGapSupported = true;
  _isFlexGapSupported = isSupported;

  return isSupported;
};

/**
 * Generate `gap` in styled-components with polyfill
 * @param gap number of px or a string of CSS value
 */
export const flexGap = (gap: number | string) => {
  if (checkFlexGapSupported()) {
    return {
      gap: typeof gap === 'string' ? gap : `${gap}px`,
    };
  }

  // Fallback for browsers that do not support flex gap
  return {
    marginLeft: `-${typeof gap === 'string' ? gap : `${gap}px`}`,
    marginTop: `-${typeof gap === 'string' ? gap : `${gap}px`}`,
    '& > *': {
      marginLeft: `${typeof gap === 'string' ? gap : `${gap}px`}`,
      marginTop: `${typeof gap === 'string' ? gap : `${gap}px`}`,
    },
  };
};
