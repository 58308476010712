import React from 'react';
import { IApplicationEntity } from 'models/Application.model';
import { ApplicationSortCriteria } from 'models/ApplicationSortCriteria.model';
import { SortDirection } from 'models/SortDirection.model';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';

type UseSortedApplicationsArgs = {
  applications: IApplicationEntity[];
  sortCriteria?: ApplicationSortCriteria;
  sortDirection?: SortDirection;
};

export const useSortedApplications = (args: UseSortedApplicationsArgs) => {
  const {
    applications,
    sortCriteria = ApplicationSortCriteria.ProcessingDays,
    sortDirection = SortDirection.DESC,
  } = args;

  const sortedApplications = React.useMemo(() => {
    const _sortedApplications = sortBy(applications, [sortCriteria]);
    if (sortDirection === SortDirection.DESC) {
      return reverse(_sortedApplications);
    }
    return _sortedApplications;
  }, [applications, sortCriteria, sortDirection]);

  return sortedApplications;
};
