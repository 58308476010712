import React from 'react';
import { ScreenWidthMatchContext } from 'components/ScreenWidthMatchProvider/ScreenWidthMatchContext';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/system';

type Props = {
  children: any;
};

const ScreenWidthMatchProvider: React.FC<Props> = ({ children }) => {
  const theme = useTheme();

  // Calculate screen width match (xs|xm|md|lg|xl|xxl|xxxl) when screen size change
  const xs = useMediaQuery(theme.breakpoints.up('xs')); // 0px
  const sm = useMediaQuery(theme.breakpoints.up('sm')); // 768px, iPad portrait
  const md = useMediaQuery(theme.breakpoints.up('md')); // 1024px, iPad landscape
  const lg = useMediaQuery(theme.breakpoints.up('lg')); // 1280px, laptop (mackbook)
  const xl = useMediaQuery(theme.breakpoints.up('xl')); // 1440px, desktop
  const xxl = useMediaQuery(theme.breakpoints.up('xxl')); // 1600px, desktop
  const xxxl = useMediaQuery(theme.breakpoints.up('xxxl')); // 1920px, desktop (design)

  // Store the memorized result in Context for children components.
  const value = React.useMemo(() => {
    return {
      xs,
      sm,
      md,
      lg,
      xl,
      xxl,
      xxxl,
    };
  }, [xs, sm, md, lg, xl, xxl, xxxl]);

  return <ScreenWidthMatchContext.Provider value={value}>{children}</ScreenWidthMatchContext.Provider>;
};

export default ScreenWidthMatchProvider;
