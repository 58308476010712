import React from 'react';
import { Chip, Grid, styled } from '@mui/material';

import buildLogo from 'assets/images/build-logo-white.svg';
import { USE_MOCK_BACKEND } from 'constants/configs';

type Props = {
  children: React.ReactNode;
};

const LoginLayout: React.FC<Props> = ({ children }) => {
  return (
    <StyledLoginSection container>
      <StyledLogoSection>
        <StyledLogo src={buildLogo} alt="Objective Build logo" height="50px" />
        {USE_MOCK_BACKEND && <StyledChip label="Mock" />}
      </StyledLogoSection>
      <StyledChildComponentsGrid container>{children}</StyledChildComponentsGrid>
    </StyledLoginSection>
  );
};

export default LoginLayout;

const StyledLoginSection = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  ...theme.mixins.flexGap('16px'),
}));

const StyledLogoSection = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...theme.mixins.flexGap('16px'),
}));

const StyledLogo = styled('img')(({ theme }) => ({
  height: '50px',
}));

const StyledChildComponentsGrid = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
}));

const StyledChip = styled(Chip)(({ theme }) => ({}));
