import React from 'react';
import { styled, TableCell, TableCellProps } from '@mui/material';

type Props = TableCellProps & {
  children?: React.ReactNode;
  isLabel?: boolean;
};

const ODLTableCell: React.FC<Props> = ({ children, isLabel = false, ...otherProps }) => {
  if (isLabel) {
    return <StyledTableLabelCell {...otherProps}>{children}</StyledTableLabelCell>;
  }
  return <StyledTableCell {...otherProps}>{children}</StyledTableCell>;
};

export default ODLTableCell;

const StyledTableLabelCell = styled(TableCell)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.text.tertiary,
  fontWeight: 700,
  padding: '6px 6px 6px 0',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.text.primary,
  padding: '6px',
}));
