import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { appStateReducer } from 'store/app-state/appStateReducer';
import { domainDataReducer } from 'store/domain-data/domainDataReducer';

export const combinedReducer = combineReducers({
  appState: appStateReducer,
  domainData: domainDataReducer,
});

type RootReducer = typeof combinedReducer;

export const rootReducer: RootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = {};
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    });
  },
});
