import { z } from 'zod';
import { checklistItemSchema, ChecklistItemUtil } from 'models/ChecklistItem.model';
import toString from 'lodash/toString';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import { assessmentResultSchema, AssessmentUtil } from 'models/Assessment.model';

export const complianceChecklistSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  order: z.number(),
  validFrom: z.string(),
  validTo: z.string(),
  appearance: z.string(),
  assessmentResult: assessmentResultSchema,
  checklistItems: checklistItemSchema.array(),
});

export type IComplianceChecklistEntity = z.infer<typeof complianceChecklistSchema>;

export const ComplianceChecklistUtil = {
  parseComplianceChecklist(json: any): IComplianceChecklistEntity {
    const complianceChecklist = {
      id: toString(get(json, 'id')),
      title: toString(get(json, 'title')),
      description: toString(get(json, 'description')),
      order: toNumber(get(json, 'order')),
      validFrom: toString(get(json, 'validFrom')),
      validTo: toString(get(json, 'validTo')),
      appearance: toString(get(json, 'appearance')),
      assessmentResult: AssessmentUtil.parseAssessment(get(json, 'assessmentResult')),
      checklistItems: ChecklistItemUtil.parseChecklistItems(get(json, 'checklistItems')),
    };
    return complianceChecklist;
  },
  parseComplianceChecklists(json: any[]): IComplianceChecklistEntity[] {
    if (!json) {
      return [];
    }
    const complianceChecklists: IComplianceChecklistEntity[] = [];
    for (const complianceChecklist of json) {
      this.parseComplianceChecklist(complianceChecklist);
    }
    return complianceChecklists;
  },
};
