import { z } from 'zod';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import toArray from 'lodash/toArray';

export enum UserStatus {
  Blocked = 'Blocked',
  Active = 'Active',
  Retired = 'Retired',
  Inactive = 'Inactive',
  Unknown = 'Unknown',
}

export const userSchema = z.object({
  id: z.string(),
  colour: z.number(),
  status: z.nativeEnum(UserStatus),
  initials: z.string(),
  lastName: z
    .string({ required_error: 'Last name is required' })
    .min(3, 'Minimum 3 characters required')
    .max(200, 'Maximum 200 characters supported'),
  revision: z.number(),
  userName: z.string(),
  firstName: z
    .string({ required_error: 'First name is required' })
    .min(3, 'Minimum 3 characters required')
    .max(200, 'Maximum 200 characters supported'),
  emailAddress: z.string().email(),
  roles: z.string().array().min(1, 'Minimum 1 role required'),
  councilCode: z.string().optional(),
  phone: z.string().optional(),
  mobile: z.string().optional(),
  jobTitle: z.string().optional(),
  statusReason: z.string().optional(),
});

export type IUserEntity = z.infer<typeof userSchema>;

export const updateUserFormSchema = userSchema.pick({
  id: true,
  firstName: true,
  lastName: true,
  emailAddress: true,
  phone: true,
  mobile: true,
  status: true,
  jobTitle: true,
  revision: true,
  userName: true,
  statusReason: true,
  roles: true,
});

export type UpdateUserFormValues = z.infer<typeof updateUserFormSchema>;

export const userRolesFormSchema = z.object({
  userId: z.string(),
  roles: z.string().array(),
});

export const addUserFormSchema = userSchema.pick({
  firstName: true,
  lastName: true,
  emailAddress: true,
  roles: true,
  status: true,
  userName: true,
});

export type AddUserFormValues = z.infer<typeof addUserFormSchema>;

export type UpdateUserRolesFormValues = z.infer<typeof userRolesFormSchema>;

export const UserUtil = {
  parseUsers(json: any): IUserEntity[] {
    if (!json) {
      return [];
    }

    const users: IUserEntity[] = [];
    for (const user of json) {
      users.push({
        id: toString(get(user, 'id')),
        colour: toNumber(get(user, 'colour')),
        status: UserUtil.parseUserStatus(toString(get(user, 'status'))),
        initials: toString(get(user, 'initials')),
        lastName: toString(get(user, 'lastName')),
        revision: toNumber(get(user, 'revision')),
        userName: toString(get(user, 'userName')),
        firstName: toString(get(user, 'firstName')),
        emailAddress: toString(get(user, 'emailAddress')),
        roles: toArray(get(user, 'roles')).map((roleId) => toString(roleId)),
        councilCode: toString(get(user, 'councilCode')),
        jobTitle: toString(get(user, 'jobTitle')),
      });
    }
    return users;
  },

  parseUser(json: any): IUserEntity {
    const user = {
      id: toString(get(json, 'id')),
      colour: toNumber(get(json, 'colour')),
      revision: toNumber(get(json, 'revision')),
      status: UserUtil.parseUserStatus(toString(get(json, 'status'))),
      initials: toString(get(json, 'initials')),
      lastName: toString(get(json, 'lastName')),
      userName: toString(get(json, 'userName')),
      firstName: toString(get(json, 'firstName')),
      emailAddress: toString(get(json, 'emailAddress')),
      councilCode: toString(get(json, 'councilCode')),
      roles: toArray(get(json, 'roles')),
      phone: toString(get(json, 'phone')),
      mobile: toString(get(json, 'mobile')),
      jobTitle: toString(get(json, 'jobTitle')),
      statusReason: toString(get(json, 'statusReason')),
    };
    return user;
  },

  parseUserStatus(status?: string) {
    for (const value of Object.values(UserStatus)) {
      if (value === UserStatus.Retired) {
        return UserStatus.Inactive;
      }
      if (status === value) {
        return value;
      }
    }
    return UserStatus.Unknown;
  },

  convertToFormValues(user?: IUserEntity): UpdateUserFormValues {
    return {
      id: toString(user?.id),
      firstName: toString(user?.firstName),
      lastName: toString(user?.lastName),
      emailAddress: toString(user?.emailAddress),
      phone: toString(user?.phone),
      mobile: toString(user?.mobile),
      status: this.parseUserStatus(user?.status),
      jobTitle: toString(user?.jobTitle),
      userName: toString(user?.userName),
      revision: toNumber(user?.revision),
      statusReason: toString(user?.statusReason),
      roles: toArray(user?.roles),
    };
  },

  convertToUserRolesFormValues(user: IUserEntity): UpdateUserRolesFormValues {
    return {
      userId: toString(user.id),
      roles: toArray(user.roles),
    };
  },
};
