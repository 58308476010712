import React from 'react';
import { useNavigate } from 'react-router-dom';

import * as EmailValidator from 'email-validator';
import { useTranslation } from 'react-i18next';

import { FormControl, FormHelperText, Grid, Link, styled, TextField, Typography } from '@mui/material';
import { Button } from '@odl/core';
import { useStoreSelector } from 'store/hooks';
import { selectIsAuthenticating } from 'store/app-state/authentication/authentication';
import { urlHelper } from 'components/RouteController/functions/urlHelper';
import FieldLabel from 'components/AuthUI/FieldLabel';
import Spacer from 'components/Spacer/Spacer';

type Props = {
  onEmailSubmit: (email: string) => void;
};

const EmailSection: React.FC<Props> = ({ onEmailSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');

  const [showError, setShowError] = React.useState(false);

  const isAuthenticating = useStoreSelector(selectIsAuthenticating);

  const isEmailValid = React.useMemo(() => {
    return EmailValidator.validate(email) || email.length === 0;
  }, [email]);

  const handleOnClickNext = React.useCallback(() => {
    if (isEmailValid && email.length > 0) {
      onEmailSubmit(email);
    } else {
      setShowError(true);
    }
  }, [email, isEmailValid, onEmailSubmit]);

  const handleOnEmailChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const onNewAccountClick = React.useCallback(() => {
    navigate(urlHelper.getURLForNewAccount());
  }, [navigate]);

  React.useEffect(() => {
    // the ODL Component Library currently doesn't expose `autoFocus` or `ref` so fall back to getElementById
    document.getElementById('textfield-email')?.focus();
  }, []);

  const isNextButtonDisabled = React.useMemo(() => {
    if (!isEmailValid) {
      return true;
    }

    if (isAuthenticating) {
      return true;
    }

    return false;
  }, [isAuthenticating, isEmailValid]);

  return (
    <StyledEmailSection container data-testid={'EmailSection'}>
      <StyledTitle>{t('Sign in')}</StyledTitle>
      <StyledFormControl fullWidth>
        <FieldLabel label={t('Email address')} required />
        <TextField
          id="textfield-email"
          fullWidth
          placeholder={t('Your work email address')}
          value={email}
          onChange={handleOnEmailChange}
          autoComplete="off"
          variant="outlined"
          error={showError}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleOnClickNext();
            }
          }}
        />
        {showError && (
          <StyledFormHelperText id="component-helper-text" error>
            <span className="icon icon-alert" /> {t('Email address is required')}
          </StyledFormHelperText>
        )}
      </StyledFormControl>

      <ODLButton
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        onClick={handleOnClickNext}
        disabled={isNextButtonDisabled}
      >
        {t('Next')}
      </ODLButton>
      <PointerLink onClick={onNewAccountClick}>
        <Typography>{t('Create a new account')}</Typography>
      </PointerLink>
      <Spacer y={1} />
    </StyledEmailSection>
  );
};

export default EmailSection;

const PointerLink = styled(Link)(({ theme }) => ({
  display: 'none',
  cursor: 'pointer',
  textDecoration: 'none',
  '& .MuiTypography-root': {
    color: theme.palette.objective.light.primary.light,
  },
  '& .MuiTypography-root:hover': {
    textDecoration: 'underline',
  },
}));

const ODLButton = styled(Button)(({ theme }) => ({
  '&.odlButton__disabled': {
    backgroundColor: theme.palette.objective.light.background.papershade,
  },
}));

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: '1em',
}));

const StyledEmailSection = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  ...theme.mixins.flexGap('24px'),
}));

const StyledTitle = styled('h2')(({ theme }) => ({
  fontSize: '24px',
  color: theme.palette.text.primary,
  fontWeight: 'normal',
  textAlign: 'center',
  margin: 0,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  ...theme.mixins.flexGap('8px'),
}));
