import React from 'react';

import { Grid, styled } from '@mui/material';
import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';
import InformationPage from 'components/AuthUI/InformationPage';
import LoginLayout from 'components/AuthUI/LoginLayout';

type Props = {
  children: React.ReactNode;
};
const AuthenticationPage: React.FC<Props> = ({ children }) => {
  const screenWidthMatch = useScreenWidthMatch();

  return (
    <StyledAuthContainer container>
      <Grid container direction="row">
        {screenWidthMatch.lg && (
          <InfoGrid container item xs>
            <InformationPage />
          </InfoGrid>
        )}
        <LoginGrid container item xs alignContent="center" justifyContent="center">
          <LoginLayout>{children}</LoginLayout>
        </LoginGrid>
      </Grid>
    </StyledAuthContainer>
  );
};

export default AuthenticationPage;

const StyledAuthContainer = styled(Grid)(({ theme }) => ({
  minHeight: '100vh',
}));

const InfoGrid = styled(Grid)(({ theme }) => ({
  alignContent: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.objective.light.background.paper,
}));

const LoginGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.objective.light.brand.build,
}));
