export enum ComplianceStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Unknown = 'Unknown',
}

// A mapping of enum values to display values
const statusDisplayMap: { [key in ComplianceStatus]: string } = {
  [ComplianceStatus.NotStarted]: 'Not Started',
  [ComplianceStatus.InProgress]: 'In Progress',
  [ComplianceStatus.Unknown]: 'Unknown',
};

export const ComplianceStatusUtil = {
  getDisplayName(status: string) {
    return statusDisplayMap[this.parseStatus(status)];
  },
  parseStatus(status: string) {
    for (const value of Object.values(ComplianceStatus)) {
      if (status === value) {
        return value;
      }
    }
    return ComplianceStatus.Unknown;
  },
};
