import { Cradle } from 'services/serviceContainer.types';
import get from 'lodash/get';
import toString from 'lodash/toString';
import { ServiceError, ServiceErrorCode } from 'services/ServiceError';
import { IPermissionEntity, PermissionUtil } from 'models/Permission.model';
import { IPermissionService } from 'services/permission/PrivilegeService.types';

export class PermissionService implements IPermissionService {
  private readonly apiClient: Cradle['apiClient'];
  private readonly i18n: Cradle['i18n'];

  constructor(args: { apiClient: Cradle['apiClient']; i18n: Cradle['i18n'] }) {
    this.apiClient = args.apiClient;
    this.i18n = args.i18n;
  }

  public async fetchPermissions() {
    const response = await this.apiClient.protectedApi.get(`/permissions`);

    const jsonArr = get(response.data, 'permissions');
    if (!Array.isArray(jsonArr)) {
      throw new ServiceError(ServiceErrorCode.ServerError, this.i18n.t(`Failed to fetch permissions`));
    }

    const permissions = this.parsePrivileges(jsonArr);

    return {
      permissions,
    };
  }

  private parsePrivileges(json: any): IPermissionEntity[] {
    if (!json) {
      return [];
    }

    const permissions: IPermissionEntity[] = [];
    for (const privilege of json) {
      permissions.push({
        id: PermissionUtil.parseName(toString(get(privilege, 'name'))),
        name: PermissionUtil.parseName(toString(get(privilege, 'name'))),
        status: PermissionUtil.parseStatus(toString(get(privilege, 'status'))),
        description: toString(get(privilege, 'description')),
      });
    }
    return permissions;
  }
}
