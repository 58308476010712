import React from 'react';
import { useStoreDispatch, useStoreSelector } from 'store/hooks';
import {
  selectIsDetailsPanelCollapsed,
  setIsDetailsPanelCollapsed,
  toggleIsDetailsPanelCollapsed,
} from 'store/app-state/ui/ui';

export const useDetailsPanelProps = () => {
  const dispatch = useStoreDispatch();
  const isCollapsed = useStoreSelector(selectIsDetailsPanelCollapsed);

  const onClickToggleCollapse = React.useCallback(() => {
    dispatch(toggleIsDetailsPanelCollapsed());
  }, [dispatch]);

  const setCollapsed = React.useCallback(
    (collapsed: boolean) => {
      dispatch(setIsDetailsPanelCollapsed(collapsed));
    },
    [dispatch],
  );

  return {
    isCollapsed,
    onClickToggleCollapse,
    setCollapsed,
  };
};
