import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ODL_ICONS } from '@odl/core/components/DataDisplay/FontIcon/FontIcon.constants';
import { MenuItemProps } from '@mui/material/MenuItem/MenuItem';
import { useStoreSelector } from 'store/hooks.ts';
import { selectIsSideNavigationCollapsed } from 'store/app-state/ui/ui.ts';

type NavigationItemProps = Omit<MenuItemProps, 'name'> & {
  to: string;
  icon: (typeof ODL_ICONS)[keyof typeof ODL_ICONS];
  isSelected?: boolean;
};

const AppSideNavigationItem: React.FC<NavigationItemProps> = ({ to, icon, title, isSelected = false }) => {
  const navigate = useNavigate();
  const isSideNavigationCollapsed = useStoreSelector(selectIsSideNavigationCollapsed);

  const tooltipTitle = React.useMemo(() => {
    if (!isSideNavigationCollapsed) {
      return '';
    }
    return title;
  }, [isSideNavigationCollapsed, title]);

  const handleOnClick = React.useCallback(() => {
    navigate(to);
  }, [navigate, to]);

  return (
    <Tooltip title={tooltipTitle} placement={'right'}>
      <ListItem onClick={handleOnClick}>
        <ListItemButton selected={isSelected}>
          <ListItemIcon>
            <span className={icon} />
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

export default AppSideNavigationItem;
