import React from 'react';
import { ButtonProps } from '@mui/material/Button/Button';
import { Button } from '@odl/core';
import { styled } from '@mui/material';

type Props = ButtonProps;

const ODLButton: React.FC<Props> = ({ title, children, ...otherProps }) => {
  return (
    <StyledButton variant={'contained'} aria-label={title} {...otherProps}>
      {title}
      {!title && children}
    </StyledButton>
  );
};

export default ODLButton;

const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-containedPrimary:hover': {
    backgroundColor: theme.palette.objective.light.primary.light,
  },
  '&.MuiButton-containedPrimary:active': {
    backgroundColor: theme.palette.objective.light.primary.dark,
  },
  '&.MuiButton-containedSecondary': {
    backgroundColor: theme.palette.objective.light.secondary.light,
  },
  '&.MuiButton-containedSecondary:hover': {
    backgroundColor: theme.palette.objective.light.secondary.main,
  },
  '&.MuiButton-containedSecondary:active': {
    backgroundColor: theme.palette.objective.light.secondary.dark,
  },
  '&&.Mui-disabled': {
    background: theme.palette.objective.light.grey[300],
    color: theme.palette.objective.light.text.disabled,
  },
}));
