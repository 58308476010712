import { z } from 'zod';
import get from 'lodash/get';
import toString from 'lodash/toString';
import toNumber from 'lodash/toNumber';

export enum AssessmentStatus {
  NotApplicable = 'NotApplicable',
  Compliant = 'Compliant',
  NonCompliant = 'NonCompliant',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export const AssessmentUtil = {
  parseStatus(status: string) {
    for (const value of Object.values(AssessmentStatus)) {
      if (status === value) {
        return value;
      }
    }
    return AssessmentStatus.NotStarted;
  },

  parseAssessment(json: any): IAssessmentEntity {
    const assessment: IAssessmentEntity = {
      status: this.parseStatus(get(json, 'status')),
      statusReason: toString(get(json, 'statusReason')),
      measurementValue: toNumber(get(json, 'measurementValue')),
    };
    return assessment;
  },
  parseAssessments(json: any[]): IAssessmentEntity[] {
    const assessments: IAssessmentEntity[] = [];
    for (const assessment of json) {
      this.parseAssessment(assessment);
    }
    return assessments;
  },
};

export const assessmentResultSchema = z.object({
  status: z.nativeEnum(AssessmentStatus),
  statusReason: z.string(),
  measurementValue: z.number(),
});

export type IAssessmentEntity = z.infer<typeof assessmentResultSchema>;
