import React from 'react';

import { styled } from '@mui/material';
import { FontIcon, IconButton } from '@odl/core';

const NotificationNavItem: React.FC = () => {
  return (
    <StyledNotificationNavItem data-testid="NotificationNavItem">
      <IconButton aria-label="notification icon button" size={'small'}>
        <FontIcon name="i-toggle-notification" />
      </IconButton>
    </StyledNotificationNavItem>
  );
};

export default NotificationNavItem;

const StyledNotificationNavItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));
