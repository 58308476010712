import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Card, CardContent, styled } from '@mui/material';

import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';

import EmailSection from 'components/AuthUI/EmailSection';
import PasswordSection from 'components/AuthUI/PasswordSection';
import VerificationScreen from 'components/AuthUI/VerificationScreen';
import InfoSection from 'components/AuthUI/InfoSection';
import { useStoreDispatch } from 'store/hooks';
import {
  getIsDomainFederated,
  loginWithCredentials,
  loginWithRedirect,
} from 'store/app-state/authentication/authentication';
import toString from 'lodash/toString';

type Props = {};

const LoginScreen: React.FC<Props> = () => {
  const dispatch = useStoreDispatch();
  const screenWidthMatch = useScreenWidthMatch();
  const [showPasswordEntry, setShowPasswordEntry] = React.useState(true);
  const [loginEmail, setLoginEmail] = React.useState('');
  const [loginError, setLoginError] = React.useState('');
  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);
  const [query] = useSearchParams();

  const handleOnEmailSubmit = React.useCallback(
    async (email: string) => {
      setLoginEmail(email);

      let domain = email.split('@')[1].toLowerCase();
      let targetPage = query.get('path') || '';
      const isDomainFederated = await dispatch(getIsDomainFederated(domain))
        .unwrap()
        .catch(() => {
          setShowPasswordEntry(false);
        });

      if (isDomainFederated) {
        await dispatch(loginWithRedirect({ domain, targetPage }));
      } else {
        setShowPasswordEntry(false);
      }
    },
    [dispatch, query],
  );

  const handleOnPasswordSubmit = React.useCallback(
    async (password: string) => {
      try {
        await dispatch(loginWithCredentials({ username: loginEmail, password })).unwrap();
      } catch (e: any) {
        setLoginError(toString(e.message));
      }
    },
    [dispatch, loginEmail],
  );

  const onEditEmail = React.useCallback(() => {
    setShowPasswordEntry(true);
    setLoginError('');
  }, []);

  const returnTo = React.useCallback((destination: string) => {
    setLoginError('');
    setShowVerifyEmail(false);
    if (destination === 'login') {
      setLoginEmail('');
    }
  }, []);

  const sectionWidth = React.useMemo(() => {
    return screenWidthMatch.sm ? '55%' : '90%';
  }, [screenWidthMatch.sm]);

  return (
    <StyledLoginSection $width={sectionWidth} data-testid={'LoginScreen'}>
      <StyledCard raised>
        <CardContent>
          {showVerifyEmail ? (
            <VerificationScreen email={loginEmail} returnTo={returnTo} />
          ) : (
            <Box>
              {loginEmail.length > 0 && !showPasswordEntry ? (
                <PasswordSection
                  onPasswordSubmit={handleOnPasswordSubmit}
                  email={loginEmail}
                  loginError={loginError}
                  onEditEmail={onEditEmail}
                />
              ) : (
                <EmailSection onEmailSubmit={handleOnEmailSubmit} />
              )}
            </Box>
          )}
        </CardContent>
        <InfoSection />
      </StyledCard>
    </StyledLoginSection>
  );
};

export default LoginScreen;

const StyledLoginSection = styled('div')<{ $width: string }>(({ theme, $width }) => ({
  width: $width,
  maxWidth: '500px',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  paddingTop: '8px',
}));
