import React from 'react';

import { styled } from '@mui/material';
import AppHeaderBar from 'components/AppHeaderBar/AppHeaderBar';
import { useDetailsPanelProps } from 'hooks/useDetailsPanelProps';

type Props = {
  navigation?: React.ReactNode;
  children?: React.ReactNode;
  rightColumn?: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ navigation, children, rightColumn }) => {
  const { isCollapsed } = useDetailsPanelProps();
  return (
    <StyledMainLayout data-testid="MainLayout">
      <AppHeaderBar />
      <StyledMainContent>
        {navigation}
        <StyledContent $isDetailsSectionCollapsed={isCollapsed}>{children}</StyledContent>
        {rightColumn}
      </StyledMainContent>
    </StyledMainLayout>
  );
};

export default MainLayout;

const StyledMainLayout = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  height: '100vh',
  minHeight: '100vh',
  overflow: 'hidden',
}));

const StyledMainContent = styled('div')(({ theme }) => ({
  paddingTop: '68px',
  display: 'flex',
  height: 'calc(100% - 68px)',
  minHeight: 'calc(100% - 68px)',
  overflow: 'hidden',
  overflowY: 'scroll',
}));

const StyledContent = styled('div')<{ $isDetailsSectionCollapsed: boolean }>(
  ({ theme, $isDetailsSectionCollapsed }) => ({
    transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1)',
    marginRight: $isDetailsSectionCollapsed ? 0 : '580px',
    display: 'flex',
    flexGrow: '1',
  }),
);
