import { z } from 'zod';
import toString from 'lodash/toString';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';

export const documentSchema = z.object({
  id: z.string(),
  uri: z.string(),
  size: z.number(),
  title: z.string(),
  filename: z.string(),
  mimeType: z.string(),
});

export type IDocumentEntity = z.infer<typeof documentSchema>;

export const DocumentUtil = {
  parseDocument(json: any): IDocumentEntity {
    const document = {
      id: toString(get(json, 'id')),
      uri: toString(get(json, 'uri')),
      size: toNumber(get(json, 'size')),
      title: toString(get(json, 'title')),
      filename: toString(get(json, 'filename')),
      mimeType: toString(get(json, 'mimeType')),
    };
    return document;
  },
  parseDocuments(json: any[]): IDocumentEntity[] {
    const documents: IDocumentEntity[] = [];
    for (const document of json) {
      documents.push(this.parseDocument(document));
    }
    return documents;
  },
};
