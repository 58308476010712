import { createAction, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { IAssignmentEntity } from 'models/Assignment.model';

// Entity Adapter
const entityAdapter = createEntityAdapter<IAssignmentEntity>();

// Actions
export const upsertAssignments = createAction<IAssignmentEntity[]>('domainData/assignments/upsertAssignments');
export const upsertAssignment = createAction<IAssignmentEntity>('domainData/assignments/upsertAssignment');

// Thunks

// Reducer
const initialState = entityAdapter.getInitialState();

export const assignmentReducer = createReducer<typeof initialState>(initialState, (builder) => {
  builder.addCase(upsertAssignments, entityAdapter.upsertMany).addCase(upsertAssignment, entityAdapter.upsertOne);
});

export const {
  selectById: selectAssignmentEntityById,
  selectIds: selectAssignmentEntityIds,
  selectEntities: selectAssignmentEntities,
  selectAll: selectAllAssignmentEntities,
  selectTotal: selectTotalAssignmentEntities,
} = entityAdapter.getSelectors((state: RootState) => state.domainData.assignment);
