import { Grid, styled, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import applicationImg from 'assets/images/application-icon.svg';
import React from 'react';

const InformationPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledInformationContainer container data-testid="InformationPage">
      <StyledTypoGraphy fontSize={'30px'}>{t('Objective Build AU Assessment portal.')}</StyledTypoGraphy>
      <StyledImage src={applicationImg} alt={t('build application illustration')} />
      <StyledTypoGraphy>{t('Manage all your applications in one place.')}</StyledTypoGraphy>
    </StyledInformationContainer>
  );
};

export default InformationPage;

const StyledInformationContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80%',
  flexDirection: 'column',
  ...theme.mixins.flexGap('32px'),
}));

const StyledTypoGraphy = styled(Typography)<{ theme?: Theme; fontSize?: string }>(({ theme, fontSize }) => ({
  fontSize: fontSize || '18px',
  color: theme.palette.text.primary,
}));

const StyledImage = styled('img')(({ theme }) => ({
  marginTop: '12px',
  width: '50%',
}));
