import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import { ToastContainerProps } from 'react-toastify/dist/types';
import { styled } from '@mui/material';

type NotificationProviderProps = Omit<ToastContainerProps, 'theme'>;

const NotificationProvider: React.FC<NotificationProviderProps> = ({ ...otherProps }) => {
  return (
    <StyledToastContainer
      autoClose={3000}
      limit={1}
      position={'top-center'}
      hideProgressBar={true}
      transition={Slide}
      {...otherProps}
    />
  );
};

export default NotificationProvider;

const StyledToastContainer = styled(ToastContainer)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  padding: 0,
  '.Toastify__toast': {
    minHeight: theme.typography.pxToRem(48),
    borderRadius: theme.spacing(0.25),
    boxShadow: 'none',
    marginBottom: theme.spacing(0.125),
  },
  '.Toastify__toast-body': {
    color: theme.palette.objective.light.text.primary,
    height: theme.typography.pxToRem(20),
  },
  '.Toastify__close-button': {
    alignSelf: 'center',
  },
  '.Toastify__toast--success': {
    backgroundColor: theme.palette.objective.light.background.success,
  },
  '.Toastify__toast--error': {
    backgroundColor: theme.palette.objective.light.background.error,
  },
  '.Toastify__toast--warning': {
    backgroundColor: theme.palette.objective.light.background.warning,
  },
  '.Toastify__toast--info': {
    backgroundColor: theme.palette.objective.light.background.info,
  },
}));
