import React from 'react';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import { objectivePalette } from 'styles/objectivePalette';
import { UserAvatarType } from 'components/UserAvatar/UserAvatar.types';
import { useRandomColors } from 'components/Role/functions/useRandomColors';
import isEmpty from 'lodash/isEmpty';

type Props = BoxProps & {
  initials: string;
  rectSize?: number;
  fontSize?: number;
  charLimit?: number;
  type?: UserAvatarType;
  username?: string;
  disabled?: boolean;
};

const UserAvatar: React.FC<Props> = ({
  initials,
  rectSize = 32,
  fontSize = 12,
  charLimit = 2,
  type = UserAvatarType.Solid,
  username = '',
  disabled = false,
  ...boxProps
}) => {
  const theme = useTheme();
  let bgColor = useRandomColors(username);

  let color = objectivePalette.light.background.paper;

  if (isEmpty(username)) {
    bgColor = objectivePalette.light.background.warning;
    color = objectivePalette.light.text.secondary;
  }

  if (disabled) {
    bgColor = theme.palette.objective.light.text.disabled;
  }

  const avatar = React.useMemo(() => {
    const avatarTypes = new Map<string, { border: string; bgcolor: string }>([
      [UserAvatarType.Solid, { border: 'none', bgcolor: objectivePalette.light.brand.build }],
      [
        UserAvatarType.Outlined,
        {
          border: `2px solid ${objectivePalette.light.brand.build}`,
          bgcolor: 'transparent',
        },
      ],
      [UserAvatarType.Dimmed, { border: 'none', bgcolor: bgColor }],
    ]);
    return avatarTypes.get(type) as { border: string; bgcolor: string };
  }, [bgColor, type]);

  return (
    <StyledBox
      width={rectSize}
      height={rectSize}
      borderRadius={rectSize}
      border={avatar.border}
      bgcolor={avatar.bgcolor}
      {...boxProps}
    >
      <StyledInitials fontSize={fontSize} fontWeight={600} color={color}>
        {initials?.slice(0, charLimit).toUpperCase() || '?'}
      </StyledInitials>
    </StyledBox>
  );
};

export default UserAvatar;

const StyledBox = styled(Box)(({ theme }) => ({
  color: theme.palette.objective.light.grey[50],
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledInitials = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
}));
