import React from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, styled, Typography } from '@mui/material';
import { FontIcon, IconButton } from '@odl/core';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { BUILD_SITE_VERSION } from 'constants/configs';

interface Props {
  onClose: VoidFunction;
  open: boolean;
}

const MinScreenWidth = 800;

const AboutDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const handleClose = () => {
    props.onClose();
  };

  return (
    <StyledDialog maxWidth="sm" onClose={handleClose} aria-labelledby="about-dialog-title" open={props.open}>
      <DialogTitle>
        <StyledDialogTitle data-testid={'DialogueTitle'}>
          <HeadingTypography>{t('Objective Build Assessment')}</HeadingTypography>
          <IconButton aria-label="close" onClick={props.onClose}>
            <FontIcon name="i-navigation-close" />
          </IconButton>
        </StyledDialogTitle>
      </DialogTitle>
      <DialogContent dividers>
        <StyledDialogContent container>
          {width > MinScreenWidth && (
            <Grid container item xs={3} justifyContent="center" alignContent="center">
              <Grid item xs>
                <LogoFontIcon name="i-product-build-branded" />
              </Grid>
            </Grid>
          )}
          <StyledCopyrightContent container>
            <Typography>Copyright © 2024 Objective Corporation Limited</Typography>
            <Typography>
              {t('Site version')}: {BUILD_SITE_VERSION}
            </Typography>
          </StyledCopyrightContent>
        </StyledDialogContent>
      </DialogContent>
    </StyledDialog>
  );
};

export default AboutDialog;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.objective.light.background.paper,
    minWidth: '500px',
  },
}));

const StyledDialogContent = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  ...theme.mixins.flexGap('12px'),
}));

const StyledCopyrightContent = styled(Grid)(({ theme }) => ({
  ...theme.mixins.flexGap('12px'),
}));

const StyledDialogTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '18px',
}));

const LogoFontIcon = styled(FontIcon)(({ theme }) => ({
  fontSize: '6em',
}));
