// Idea: https://www.coryetzkorn.com/blog/dont-fear-empty-divs/
//   Basically, we could use spacing component in between components instead of using margin to achieve more flexibility
//   That is, spacing may not tightly coupled with a child component itself

import React from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';

type Props = BoxProps & {
  x?: number; // multiplier of theme.spacing
  y?: number; // multiplier of theme.spacing
  basis?: number; // multiplier of theme.spacing
};

const Spacer: React.FC<Props> = ({ x, y, basis, ...restProps }) => {
  const theme = useTheme();
  return (
    <Box
      data-testid="Spacer"
      width={x ? theme.spacing(x) : undefined}
      height={y ? theme.spacing(y) : undefined}
      flexBasis={basis ? theme.spacing(basis) : undefined}
      {...restProps}
    />
  );
};

export default Spacer;
