import { combineReducers } from '@reduxjs/toolkit';
import { authenticationReducer } from 'store/app-state/authentication/authentication';
import { uiReducer } from 'store/app-state/ui/ui';
import { loadingReducer } from 'store/app-state/loading/loading';

export const appStateReducer = combineReducers({
  authentication: authenticationReducer,
  loading: loadingReducer,
  ui: uiReducer,
});
