import { z } from 'zod';

export enum PermissionName {
  ViewApplications = 'ViewApplications',
  ManageApplications = 'ManageApplications',
  CreateUsers = 'CreateUsers',
  ViewUsers = 'ViewUsers',
  UpdateUsers = 'UpdateUsers',
  DeleteUsers = 'DeleteUsers',
  ManageUsers = 'ManageUsers',
  Unknown = 'Unknown',
}

export enum PermissionStatus {
  Granted = 'Granted',
  Denied = 'Denied',
  Unknown = 'Unknown',
}

export const permissionSchema = z.object({
  id: z.string(),
  name: z.nativeEnum(PermissionName),
  description: z.string(),
  status: z.nativeEnum(PermissionStatus),
});

export type IPermissionEntity = z.infer<typeof permissionSchema>;

export const PermissionUtil = {
  parseName(name: string) {
    for (const value of Object.values(PermissionName)) {
      if (value === name) {
        return value;
      }
    }
    return PermissionName.Unknown;
  },

  parseStatus(status: string) {
    for (const value of Object.values(PermissionStatus)) {
      if (value === status) {
        return value;
      }
    }
    return PermissionStatus.Unknown;
  },
};
