import { styled } from '@mui/material';
import { NavigationHelp, NavigationMenu, NavigationToggle } from '@odl/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useAppNavigationProps } from 'hooks/useAppNavigationProps';
import { AppSideNavigationTypes } from 'components/AppSideNavigation/AppSideNavigation.types';
import AppSideNavigationItem from 'components/AppSideNavigation/AppSideNavigationItem';
import {
  USE_CREATE_APPLICATION,
  USE_DASHBOARD,
  USE_REPORTING,
  USE_TASKS_AND_SCHEDULING,
  USE_TEAMS,
  USE_USERS_AND_PERMISSIONS,
} from 'constants/configs';

type Props = {
  activeMenuItemType?: AppSideNavigationTypes;
};

const AppSideNavigation: React.FC<Props> = ({ activeMenuItemType }) => {
  const { t } = useTranslation();

  const { isCollapsed, onClickToggleCollapse } = useAppNavigationProps();

  const dataTestId = React.useMemo(() => {
    if (!isCollapsed) {
      return `AppSideNavigationExpanded`;
    }
    return `AppSideNavigationCollapsed`;
  }, [isCollapsed]);

  return (
    <StyledAppSideNavigation data-testid={dataTestId}>
      <NavigationMenu isOpen={!isCollapsed}>
        {USE_DASHBOARD && (
          <AppSideNavigationItem
            to={'/dashboard'}
            icon={'i-controls-dashboard'}
            title={t('Dashboard')}
            isSelected={activeMenuItemType === AppSideNavigationTypes.Dashboard}
          />
        )}

        <AppSideNavigationItem
          to={'/applications'}
          icon={'i-operations-catalog-publish'}
          title={t('Applications')}
          isSelected={activeMenuItemType === AppSideNavigationTypes.Applications}
        />

        {USE_TASKS_AND_SCHEDULING && (
          <AppSideNavigationItem
            to={'/tasks-and-scheduling'}
            icon={'i-time-reminder'}
            title={t('Tasks and scheduling')}
            isSelected={activeMenuItemType === AppSideNavigationTypes.TasksAndScheduling}
          />
        )}

        {USE_REPORTING && (
          <AppSideNavigationItem
            to={'/reporting'}
            icon={'i-data-chart-multitype'}
            title={t('Reporting')}
            isSelected={activeMenuItemType === AppSideNavigationTypes.Reporting}
          />
        )}

        {USE_TEAMS && (
          <AppSideNavigationItem
            to={'/teams'}
            icon={'i-user-events-alt'}
            title={t('Teams')}
            isSelected={activeMenuItemType === AppSideNavigationTypes.Teams}
          />
        )}

        {USE_USERS_AND_PERMISSIONS && (
          <AppSideNavigationItem
            to={'/users-and-permissions'}
            icon={'i-catalog-hyper-protect-crypto-services'}
            title={t('Users and permissions')}
            isSelected={activeMenuItemType === AppSideNavigationTypes.UsersAndPermissions}
          />
        )}

        <AppSideNavigationItem
          to={'/all-applications'}
          icon={'i-operations-catalog-publish'}
          title={t('All applications')}
          isSelected={activeMenuItemType === AppSideNavigationTypes.AllApplications}
        />

        {USE_CREATE_APPLICATION && (
          <AppSideNavigationItem
            to={'/create-application'}
            icon={'i-operations-catalog-publish'}
            title={t('Create applications')}
            isSelected={activeMenuItemType === AppSideNavigationTypes.CreateApplication}
          />
        )}

        <NavigationToggle
          isOpen={!isCollapsed}
          ariaLabel={!isCollapsed ? `"Collapse side menu"` : `Expand side menu`}
          toggleDrawer={onClickToggleCollapse}
        />

        <NavigationHelp href="https://www.objective.com" title={t('Help')} />
      </NavigationMenu>
    </StyledAppSideNavigation>
  );
};

export default AppSideNavigation;

const StyledAppSideNavigation = styled('div')(({ theme }) => ({
  '& .MuiList-root': {
    borderRight: `1px solid ${theme.palette.objective.light.grey[400]}`,
    marginTop: '68px !important',
  },
}));
