import React from 'react';
import { useStoreSelector } from 'store/hooks';
import { selectApplicationEntityById } from 'store/domain-data/application/application';
import ODLChip from 'components/ODL/ODLChip/ODLChip';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

type Props = {
  applicationId: string;
};

const ApplicationNotificationChip: React.FC<Props> = ({ applicationId }) => {
  const { t } = useTranslation();
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  const notificationLabel = React.useMemo(() => {
    return t('To Do');
  }, [t]);

  if (!application || isEmpty(application.notifications)) {
    return t('To Do');
  }

  return <ODLChip label={notificationLabel} />;
};

export default ApplicationNotificationChip;
