import React from 'react';
import { Chip, styled } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';

type Props = ChipProps & {
  color?: string;
};

const ODLChip: React.FC<Props> = ({ label, ...otherProps }) => {
  return <StyledChip label={label} size="small" {...otherProps} />;
};

export default ODLChip;

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  padding: '4px 6px',
}));
