import { useStoreDispatch } from 'store/hooks';
import { loginWithCachedState } from 'store/app-state/authentication/authentication';
import React from 'react';

export const useAuthenticationRoutine = () => {
  const dispatch = useStoreDispatch();

  // Automatically login if user has cached authentication state
  React.useEffect(() => {
    dispatch(loginWithCachedState());
  }, [dispatch]);
};
