import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { Button } from '@odl/core';
import { signUp } from 'aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import * as EmailValidator from 'email-validator';

import VerificationScreen from 'components/AuthUI/VerificationScreen';
import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';
import Spacer from 'components/Spacer/Spacer';
import FieldLabel from 'components/AuthUI/FieldLabel';
import InfoSection from 'components/AuthUI/InfoSection';

const NewAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenWidthMatch = useScreenWidthMatch();
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [signUpError, setSignUpError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);

  // eslint-disable-next-line
  const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

  async function signingUp() {
    await signUp({
      username: email,
      password: password,
      options: {
        userAttributes: {
          given_name: firstName,
          family_name: lastName,
        },
        autoSignIn: true,
      },
    })
      .then(() => {
        setUserCreated(true);
      })
      .catch((error: any) => {
        setSignUpError(null);
        setEmailError(null);
        if (error.message === 'Username should be an email.' || error.message === 'Username cannot be empty') {
          setEmailError(t('Email should be a valid email address'));
        } else if (error.message === 'An account with the given email already exists.') {
          setEmailError(error.message);
        } else if (error.message.toLowerCase().includes('password')) {
          setPasswordError(
            t(
              'Password should contain at least 8 characters with an uppercase letter, lowercase letter a number and a special character.',
            ),
          );
        } else {
          setSignUpError(error.message);
        }
      });
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setSignUpError(null);
  };

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setSignUpError(null);
  };

  const onNextClick = () => {
    let error = false;
    setEmailError(null);
    setPasswordError(null);
    if (email.length === 0) {
      setEmailError(t('Email address cannot be empty'));
      error = true;
    }
    if (EmailValidator.validate(email) === false) {
      setEmailError(t('Email address is not valid'));
      error = true;
    }
    if (password.length === 0) {
      setPasswordError(t('Password cannot be empty'));
      error = true;
    } else if (!passwordRegex.test(password)) {
      setPasswordError(
        t(
          'Password should contain at least 8 characters with an uppercase letter, lowercase letter a number and a special character.',
        ),
      );
    }
    if (firstName.length === 0) {
      setFirstNameError(t('Given name cannot be empty'));
      error = true;
    } else {
      setFirstNameError(null);
    }
    if (lastName.length === 0) {
      setLastNameError(t('Family name cannot be empty'));
      error = true;
    } else {
      setLastNameError(null);
    }

    if (!error) {
      signingUp();
    }
  };

  const onSignInClick = () => {
    navigate('/auth/signin');
  };

  useEffect(() => {
    // the ODL Component Library currently doesn't expose `autoFocus` or `ref` so fall back to getElementById
    document.getElementById('textfield-email')?.focus();
  }, []);

  return (
    <Box width={screenWidthMatch.sm ? '55%' : '90%'} maxWidth="500px">
      <Card>
        <CardContent>
          {userCreated ? (
            <VerificationScreen email={email} />
          ) : (
            <StyledFormSection container>
              <Grid item>
                <Typography fontSize={24} align="center">
                  {t('Create new account')}
                </Typography>
                <Typography align="center">{t('To create a new account, enter your details below.')}</Typography>
              </Grid>

              <StyledFormControl fullWidth>
                <FieldLabel required label={t('Email')} />
                <TextField
                  id="textfield-email"
                  autoComplete="off"
                  fullWidth
                  placeholder={t('Your work email address')}
                  onChange={onEmailChange}
                  variant="outlined"
                  error={emailError !== null}
                />
                {emailError && (
                  <StyledFormHelperText id="component-helper-text" error>
                    <span className="icon icon-alert odlTextInput__icon" /> {emailError}
                  </StyledFormHelperText>
                )}
              </StyledFormControl>

              <StyledFormControl>
                <FieldLabel required label={t('Given name')} />
                <TextField
                  id="textfield-firstName"
                  autoComplete="off"
                  fullWidth
                  onChange={onFirstNameChange}
                  variant="outlined"
                  error={firstNameError !== null}
                />

                {firstNameError && (
                  <StyledFormHelperText id="component-helper-text" error>
                    <span className="icon icon-alert odlTextInput__icon" /> {firstNameError}
                  </StyledFormHelperText>
                )}
              </StyledFormControl>

              <StyledFormControl>
                <FieldLabel required label={t('Family name')} />
                <TextField
                  id="textfield-lastName"
                  autoComplete="off"
                  fullWidth
                  onChange={onLastNameChange}
                  variant="outlined"
                  error={lastNameError !== null}
                />

                {lastNameError && (
                  <StyledFormHelperText id="component-helper-text" error>
                    <span className="icon icon-alert odlTextInput__icon" /> {lastNameError}
                  </StyledFormHelperText>
                )}
              </StyledFormControl>

              <StyledFormControl>
                <FieldLabel required label={t('Password')} />
                <TextField
                  id="textfield-password"
                  type="password"
                  autoComplete="off"
                  fullWidth
                  placeholder={t('Password')}
                  onChange={onPasswordChange}
                  variant="outlined"
                  error={passwordError !== null}
                />

                {passwordError && (
                  <StyledFormHelperText id="component-helper-text" error>
                    <span className="icon icon-alert odlTextInput__icon" /> {passwordError}
                  </StyledFormHelperText>
                )}
              </StyledFormControl>
              <Grid item>
                {signUpError && (
                  <Grid item>
                    <Typography>{signUpError}</Typography>
                    <Spacer y={4} />
                  </Grid>
                )}
                <Button onClick={onNextClick} size="large" variant="contained" fullWidth>
                  {t('Next')}
                </Button>
              </Grid>

              <Grid container item direction="row">
                <Typography>{t('Already have an account?')}</Typography>
                <PointerLink onClick={onSignInClick}>
                  <Typography>{t('Sign in')}</Typography>
                </PointerLink>
              </Grid>
            </StyledFormSection>
          )}
        </CardContent>
        <InfoSection />
      </Card>
    </Box>
  );
};

export default NewAccount;

const PointerLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: '4px',
  textDecoration: 'none',
  '& .MuiTypography-root': {
    color: theme.palette.objective.light.primary.light,
  },
  '& .MuiTypography-root:hover': {
    textDecoration: 'underline',
  },
}));

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: '1em',
}));

const StyledFormSection = styled(Grid)(({ theme }) => ({
  flexDirection: 'column',
  ...theme.mixins.flexGap('24px'),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  ...theme.mixins.flexGap('8px'),
}));
