import { Divider, Grid, styled } from '@mui/material';
import { AppHeader } from '@odl/core';

import LogoImage from 'assets/images/logo.svg';
import NotificationNavItem from 'components/AppHeaderBar/NotificationNavItem';
import UserNavItem from 'components/AppHeaderBar/UserNavItem';
import { useTranslation } from 'react-i18next';

const AppHeaderBar = () => {
  const { t } = useTranslation();

  return (
    <AppHeader brand="planbuild" data-testid="AppHeader">
      <StyledHeader>
        <StyledLogo src={LogoImage} alt={t('Objective Build')} />
        <StyledHeaderRightColumn container>
          <NotificationNavItem />
          <Divider orientation="vertical" flexItem />
          <UserNavItem />
        </StyledHeaderRightColumn>
      </StyledHeader>
    </AppHeader>
  );
};

export default AppHeaderBar;

const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
  padding: '3px 0',
}));

const StyledLogo = styled('img')(({ theme }) => ({
  width: '160px',
  height: '24px',
}));

const StyledHeaderRightColumn = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  width: 'auto',
  ...theme.mixins.flexGap('12px'),
}));
