import React from 'react';
import { styled } from '@mui/material';
import { useDetailsPanelProps } from 'hooks/useDetailsPanelProps';
import { FontIcon, IconButton } from '@odl/core';

type Props = {
  children: React.ReactNode;
};

const DetailsPanel: React.FC<Props> = ({ children }) => {
  const { isCollapsed, onClickToggleCollapse } = useDetailsPanelProps();

  return (
    <StyledDetailsPanel $isCollapsed={isCollapsed}>
      <StyledContent>
        <StyledCloseIcon size={'small'} onClick={onClickToggleCollapse}>
          <FontIcon name={'i-navigation-close'} />{' '}
        </StyledCloseIcon>
        {children}
      </StyledContent>
    </StyledDetailsPanel>
  );
};

export default DetailsPanel;

const StyledDetailsPanel = styled('div')<{ $isCollapsed: boolean }>(({ theme, $isCollapsed }) => ({
  transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1)',
  width: $isCollapsed ? '0' : '580px',
  position: 'fixed',
  right: 0,
  top: '68px',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  borderLeft: `${theme.palette.background.default} solid 1px`,
  backgroundColor: theme.palette.background.paper,
}));

const StyledContent = styled('div')(({ theme }) => ({
  minWidth: '580px',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
}));

const StyledCloseIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 15,
  top: 25,
}));
