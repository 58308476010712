import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { Button } from '@odl/core';
import * as EmailValidator from 'email-validator';
import { useTranslation } from 'react-i18next';

import { useScreenWidthMatch } from 'hooks/useScreenWidthMatch';
import Spacer from 'components/Spacer/Spacer';
import FieldLabel from 'components/AuthUI/FieldLabel';
import InfoSection from 'components/AuthUI/InfoSection';
import { confirmResetPassword, resetPassword } from 'store/app-state/authentication/authentication';
import { useStoreDispatch } from 'store/hooks';
import { urlHelper } from 'components/RouteController/functions/urlHelper';

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useStoreDispatch();
  const screenWidthMatch = useScreenWidthMatch();

  const [email, setEmail] = React.useState<string>('');
  const [validEmail, setValidEmail] = React.useState<boolean>(false);
  const [resetLinkSent, setResetLinkSent] = React.useState<number>(0);
  const [code, setCode] = React.useState<string>('');
  const [codeError, setCodeError] = React.useState<string | null>(null);
  const [password, setPassword] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<string | null>(null);
  const [resetSuccessful, setResetSuccessful] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>();

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);

    if (EmailValidator.validate(event.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const onCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
    setCodeError(null);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(null);
  };

  const onEmailKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      await onResetRequest();
    }
  };

  const onKeyPress = async (event: any) => {
    if (event.key === 'Enter') {
      await onResetSubmit();
    }
  };

  const onBackClick = () => {
    navigate(urlHelper.getURLForSignIn());
  };

  const onResetRequest = async () => {
    if (!validEmail) {
      setErrorText('You have entered an invalid email address');
      return;
    }
    await dispatch(resetPassword({ username: email }));
    setResetLinkSent((sent) => sent + 1);
  };

  const onBackToLoginClick = () => {
    navigate('/auth/signin');
  };

  const onResetSubmit = async () => {
    await dispatch(confirmResetPassword({ username: email, confirmationCode: code, newPassword: password })).catch(
      (error) => {
        console.error('Password reset unsucessful ', error);
        if (error.message.toLowerCase().includes('password')) {
          setPasswordError(
            t(
              'Password should contain at least 8 characters with an uppercase letter, lowercase letter a number and a special character.',
            ),
          );
        }
        if (error.message.toLowerCase().includes('code')) {
          setCodeError(t('Invalid verification code provided.'));
        }
      },
    );
    setResetSuccessful(true);
  };

  React.useEffect(() => {
    if (resetSuccessful) {
      setTimeout(() => {
        navigate(urlHelper.getURLForSignIn());
      }, 2000);
    }
  }, [resetSuccessful, navigate]);

  React.useEffect(() => {
    // the ODL Component Library currently doesn't expose `autoFocus` or `ref` so fall back to getElementById
    document.getElementById('textfield-email')?.focus();
    document.getElementById('textfield-reset-code')?.focus();
  }, []);

  return (
    <Box width={screenWidthMatch.sm ? '55%' : '90%'} maxWidth="500px">
      <Card raised>
        <CardContent>
          <Grid container direction="column">
            <Spacer y={2} />
            {resetLinkSent > 0 && !resetSuccessful && (
              <Grid>
                <Grid container item xs justifyContent="center">
                  <Typography>{t('Enter the code sent to your email inbox and a new password below.')}</Typography>
                </Grid>
                <Spacer y={4} />
                <FormControl fullWidth>
                  <Grid item>
                    <Grid container item justifyContent="flex-start">
                      <FieldLabel required label={t('Password reset code')} />
                    </Grid>
                    <Spacer y={2} />
                    <Grid item>
                      <TextField
                        id="textfield-reset-code"
                        label=""
                        variant="outlined"
                        fullWidth
                        value={code}
                        onChange={onCodeChange}
                        error={errorText !== undefined && errorText.includes('code')}
                      />
                    </Grid>
                  </Grid>
                  {codeError !== null && (
                    <StyledFormHelperText id="component-helper-text" error>
                      <span className="icon icon-alert" /> {codeError}
                    </StyledFormHelperText>
                  )}
                  <Spacer y={2} />
                  <Grid item>
                    <Grid container item justifyContent="flex-start">
                      <FieldLabel required label={t('New password')} />
                    </Grid>
                    <Spacer y={2} />
                    <Grid item>
                      <TextField
                        id="textfield-password"
                        type="password"
                        fullWidth
                        placeholder={t('Password')}
                        variant="outlined"
                        onChange={onPasswordChange}
                        onKeyPress={onKeyPress}
                        error={errorText !== undefined && errorText.includes('password')}
                      />
                    </Grid>
                  </Grid>
                  {passwordError !== null && (
                    <StyledFormHelperText id="component-helper-text" error>
                      <span className="icon icon-alert" /> {passwordError}
                    </StyledFormHelperText>
                  )}
                  <Spacer y={2} />
                </FormControl>
                <Grid item>
                  <ODLButton
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={onResetSubmit}
                    disabled={!validEmail || codeError !== null || passwordError !== null}
                  >
                    {t('Update password')}
                  </ODLButton>
                </Grid>
                <Spacer y={2} />
                <Grid container item direction="row">
                  <Grid container item xs justifyContent="flex-start">
                    <PointerLink onClick={onResetRequest}>
                      <Grid container item justifyContent="flex-start" alignContent="center" direction="row">
                        <Grid item>
                          <Typography>{t('Resend Code')}</Typography>
                        </Grid>
                        <Spacer x={1} />
                        <Grid item xs={1}>
                          {resetLinkSent > 1 && <Box className="icon icon-check-circle-outline" fontSize={14} />}
                        </Grid>
                      </Grid>
                    </PointerLink>
                  </Grid>
                  <Grid container item xs justifyContent="flex-end">
                    <PointerLink onClick={onBackToLoginClick}>
                      <Typography>{t('Back to sign in')}</Typography>
                    </PointerLink>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {resetLinkSent === 0 && (
              <React.Fragment>
                <Grid container item justifyContent="center">
                  <Typography>{t('To reset your password, please enter your email address')}</Typography>
                </Grid>

                <Spacer y={4} />
                <Grid item>
                  <Grid container item justifyContent="flex-start">
                    <FieldLabel required label={t('Email address')} />
                  </Grid>
                  <Spacer y={2} />
                  <Grid item>
                    <TextField
                      id="textfield-email"
                      label=""
                      variant="outlined"
                      fullWidth
                      placeholder={t('Your work email address')}
                      onChange={onEmailChange}
                      autoComplete="off"
                      onKeyPress={onEmailKeyPress}
                    />
                  </Grid>
                </Grid>
                <Spacer y={4} />
                <Grid item>
                  <ODLButton
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={onResetRequest}
                    disabled={!validEmail || codeError !== null || passwordError !== null}
                  >
                    {t('Update password')}
                  </ODLButton>
                </Grid>
                <Spacer y={3} />
                <Grid container item xs justifyContent="flex-start">
                  <PointerLink onClick={onBackClick}>
                    <Typography>{t('Back to sign in')}</Typography>
                  </PointerLink>
                </Grid>
              </React.Fragment>
            )}
            {resetLinkSent > 0 && resetSuccessful && (
              <React.Fragment>
                <Grid container direction="column" justifyContent="center">
                  <Spacer y={6} />
                  <Grid container item justifyContent="center">
                    <Icon className="icon icon-check-circle" />
                  </Grid>
                  <Spacer y={6} />
                  <Grid item>
                    <Typography variant="h5" align="center">
                      {t('Password reset successful')}
                    </Typography>
                  </Grid>
                  <Spacer y={6} />
                  <Grid item>
                    <Typography align="center">{t('Returning back to sign in page')}</Typography>
                  </Grid>
                </Grid>
                <Spacer y={6} />
              </React.Fragment>
            )}
          </Grid>
        </CardContent>
        <InfoSection />
      </Card>
    </Box>
  );
};

export default ResetPassword;

const PointerLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  '& .MuiTypography-root': {
    color: theme.palette.objective.light.primary.light,
  },
  '& .MuiTypography-root:hover': {
    textDecoration: 'underline',
  },
}));

const ODLButton = styled(Button)(({ theme }) => ({
  '&.odlButton__disabled': {
    backgroundColor: theme.palette.objective.light.background.papershade,
  },
}));

const Icon = styled('div')(({ theme }) => ({
  color: theme.palette.objective.light.success.main,
  fontSize: '30px',
}));

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  fontSize: '1em',
}));
