import { ThemeProvider } from '@odl/core';
import RouteController from 'components/RouteController/RouteController';
import { useAuthenticationRoutine } from 'hooks/useAuthenticationRoutine';
import themeComponentOverride from 'styles/themeComponentOverride';
import themeOverride from 'styles/themeOverride.ts';
import React from 'react';
import ScreenWidthMatchProvider from 'components/ScreenWidthMatchProvider/ScreenWidthMatchProvider';

const App = () => {
  useAuthenticationRoutine();

  return (
    <ThemeProvider
      mode="light"
      brand="planbuild"
      theme={{ ...themeOverride }}
      componentThemeOverrides={themeComponentOverride}
    >
      <ScreenWidthMatchProvider>
        <RouteController />
      </ScreenWidthMatchProvider>
    </ThemeProvider>
  );
};

export default App;
