export enum RFIStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  AwaitingResponse = 'AwaitingResponse',
  AwaitingApproval = 'AwaitingApproval',
  Completed = 'Completed',
}

export const RFIsUtil = {
  parseStatus(status: string) {
    for (const value of Object.values(RFIStatus)) {
      if (status === value) {
        return value;
      }
    }
    return RFIStatus.NotStarted;
  },
};
