import { IFederationService } from 'services/federation/FederationService.types';
import get from 'lodash/get';
import { Cradle } from 'services/serviceContainer.types';

export class FederationService implements IFederationService {
  private readonly apiClient: Cradle['apiClient'];

  constructor(args: Pick<Cradle, 'apiClient'>) {
    this.apiClient = args.apiClient;
  }

  public async getIsUserFederated(domain: string): Promise<boolean> {
    const response = await this.apiClient.authApi.get(`v1/cognito/${domain}/federated`);
    return Boolean(get(response.data, 'IsFederated'));
  }
}
