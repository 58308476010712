import React from 'react';
import { IScreenWidthMatchContextValue } from 'components/ScreenWidthMatchProvider/ScreenWidthMatchProvider.types';
import { ScreenWidthMatchContext } from 'components/ScreenWidthMatchProvider/ScreenWidthMatchContext';

export const useScreenWidthMatch = (): IScreenWidthMatchContextValue => {
  const { xs, sm, md, lg, xl, xxl, xxxl } = React.useContext(ScreenWidthMatchContext);

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
  };
};
