import React from 'react';
import { Grid, styled, Typography } from '@mui/material';

import Spacer from 'components/Spacer/Spacer';

interface Props {
  label: string;
  required?: boolean;
}

const FieldLabel: React.FC<Props> = (props) => {
  return (
    <StyledGrid container item direction="row">
      <Grid item>
        <Typography className="label">{props.label}</Typography>
      </Grid>
      {props.required && (
        <React.Fragment>
          <Spacer x={1} />
          <Grid item>
            <Typography className="required">{'*'}</Typography>
          </Grid>
        </React.Fragment>
      )}
    </StyledGrid>
  );
};

export default FieldLabel;

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .MuiTypography-root.label': {
    fontWeight: 600,
  },
  '& .MuiTypography-root.required': {
    color: theme.palette.objective.light.error.main,
  },
}));
