export enum ReferralStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export const ReferralUtil = {
  parseStatus(status: string) {
    for (const value of Object.values(ReferralStatus)) {
      if (status === value) {
        return value;
      }
    }
    return ReferralStatus.NotStarted;
  },
};
