const store: Record<string, string> = {};

// Mock localStorage to stop errors in test: localStorage is not available for opaque origins
export const localStorage: Storage = {
  getItem: (key: string) => {
    return store[key];
  },
  setItem: (key: string, value: string) => {
    store[key] = value;
  },
  length: Object.keys(store).length,
  removeItem: (key: string) => {
    delete store[key];
  },
  clear: () => {
    Object.keys(store).forEach((key) => {
      delete store[key];
    });
  },
  key: (index: number) => {
    return Object.keys(store)[index];
  },
};
