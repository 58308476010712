import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, FormHelperText, Grid, Link, styled, TextField, Typography } from '@mui/material';
import { Button } from '@odl/core';
import { useTranslation } from 'react-i18next';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';

import FieldLabel from 'components/AuthUI/FieldLabel';
import Spacer from 'components/Spacer/Spacer';
import { urlHelper } from 'components/RouteController/functions/urlHelper';

type Props = {
  email: string;
  returnTo?: (destination: string) => void;
};

const VerificationScreen: React.FC<Props> = ({ email, returnTo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [code, setCode] = React.useState('');
  const [codeError, setCodeError] = React.useState('');
  const [verified, setVerified] = React.useState(false);
  const [alreadyVerified, setAlreadyVerified] = React.useState(false);
  const [resent, setResent] = React.useState(false);

  const handleOnCodeChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value.trim());
  }, []);

  const handleOnBackClick = React.useCallback(() => {
    navigate(urlHelper.getURLForSignIn());
  }, [navigate]);

  const handleOnConfirmingSignUpClick = React.useCallback(async () => {
    try {
      await confirmSignUp({ username: email, confirmationCode: code });
      setVerified(true);
      if (returnTo) {
        returnTo('password');
      }
    } catch (error: any) {
      console.error('error signing in', error.__type);
      setCodeError(error.message);
      if (error.message.includes('status is CONFIRMED')) {
        setCodeError(t('Your email address has already been confirmed'));
        setAlreadyVerified(true);
      }
    }
  }, [code, email, returnTo, t]);

  const handleOnResendClick = React.useCallback(async () => {
    try {
      await resendSignUpCode({ username: email });
      setResent(true);
    } catch (error: any) {
      console.error(error);
    }
  }, [email]);

  const handleOnBackToLoginClick = React.useCallback(() => {
    if (returnTo) {
      returnTo('login');
    } else {
      navigate(urlHelper.getURLForSignIn());
    }
  }, [navigate, returnTo]);

  React.useEffect(() => {
    if (verified) {
      setTimeout(() => {
        navigate(urlHelper.getURLForSignIn());
      }, 2000);
    }
  }, [verified, navigate]);

  React.useEffect(() => {
    // the ODL Component Library currently doesn't expose `autoFocus` or `ref` so fall back to getElementById
    document.getElementById('textfield-code')?.focus();
  });

  return (
    <Box>
      {verified ? (
        <React.Fragment>
          <Grid container direction="column" justifyContent="center">
            <Spacer y={6} />
            <Grid container item justifyContent="center">
              <Icon className="icon icon-check-circle" />
            </Grid>
            <Spacer y={6} />
            <Grid item>
              <Typography variant="h5" align="center">
                {t('Email address has been verified')}
              </Typography>
            </Grid>
            <Spacer y={6} />
            <Grid item>
              <Typography align="center">{t('Returning back to sign in page')}</Typography>
            </Grid>
          </Grid>
          <Spacer y={6} />
        </React.Fragment>
      ) : (
        <Grid container item direction="column" xs justifyContent="center">
          <Spacer y={4} />
          <Grid item>
            <Typography variant="h5" align="center">
              {t('Verify new account')}
            </Typography>
          </Grid>
          <Spacer y={4} />
          <Grid item>
            <Typography align="center">
              {t(`Please enter below the verification code sent to your {{email}} inbox`, { email })}
            </Typography>
          </Grid>
          <Spacer y={8} />
          <Grid container item justifyContent="flex-start">
            <FieldLabel required label={t('Verification Code')} />
          </Grid>
          <Spacer y={2} />
          <Grid item>
            <TextField
              id="textfield-code"
              fullWidth
              onChange={handleOnCodeChange}
              placeholder={t('Code')}
              variant="outlined"
              disabled={alreadyVerified}
              error={codeError !== null && !verified}
            />
          </Grid>
          {codeError && !verified && (
            <FormHelperText id="component-helper-text" error>
              <span className="icon icon-alert odlTextInput__icon" /> {codeError}
            </FormHelperText>
          )}
          <Spacer y={4} />
          <Grid item>
            {alreadyVerified ? (
              <Button onClick={handleOnBackClick} size="large" variant="contained" fullWidth>
                {t('Back to sign in')}
              </Button>
            ) : (
              <Button onClick={handleOnConfirmingSignUpClick} size="large" variant="contained" fullWidth>
                {t('Verify')}
              </Button>
            )}
          </Grid>
          <Spacer y={6} />
          <Grid container item direction="row">
            <Grid container item xs justifyContent="flex-start">
              <PointerLink onClick={handleOnResendClick}>
                <Grid container item justifyContent="flex-start" alignContent="center" direction="row">
                  <Grid item>
                    <Typography>{t('Resend Code')}</Typography>
                  </Grid>
                  <Spacer x={1} />
                  <Grid item xs={1}>
                    {resent && <Box className="icon icon-check-circle-outline" fontSize={14} />}
                  </Grid>
                </Grid>
              </PointerLink>
            </Grid>
            <Grid container item xs justifyContent="flex-end">
              <PointerLink onClick={handleOnBackToLoginClick}>
                <Typography>{t('Back to sign in')}</Typography>
              </PointerLink>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default VerificationScreen;

const PointerLink = styled(Link)({
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover .MuiTypography-root': {
    textDecoration: 'underline',
  },
});

const Icon = styled('div')(({ theme }) => ({
  color: theme.palette.objective.light.success.main,
  fontSize: '30px',
}));
