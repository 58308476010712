import React from 'react';
import { useStoreSelector } from 'store/hooks';
import { selectApplicationEntityById } from 'store/domain-data/application/application';
import ODLChip from 'components/ODL/ODLChip/ODLChip';
import { useTranslation } from 'react-i18next';
import { ReferralStatus } from 'models/Referral.model';

type Props = {
  applicationId: string;
};

const ApplicationReferralChip: React.FC<Props> = ({ applicationId }) => {
  const { t } = useTranslation();
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  const referralLabel = React.useMemo(() => {
    return t('To Do');
  }, [t]);

  if (!application || application.referrals?.summaryStatus === ReferralStatus.NotStarted) {
    return t('To Do');
  }

  return <ODLChip label={referralLabel} />;
};

export default ApplicationReferralChip;
