import { IComplianceService } from 'services/compliance/ComplianceService.types';
import {
  IApplicationChecklistResponse,
  IAssessmentResult,
  IChecklist,
} from 'services/application/ApplicationService.types';
import { Cradle } from 'services/serviceContainer.types';

export class ComplianceService implements IComplianceService {
  private readonly apiClient: Cradle['apiClient'];

  constructor(args: { apiClient: Cradle['apiClient'] }) {
    this.apiClient = args.apiClient;
  }

  /**
   * Gets list of compliance checklists for the given application id
   * @param applicationId The application id to retrieve checklists for
   * @returns List of compliance checklists
   */
  public async fetchComplianceChecklistBasedOnApplicationId(applicationId: string): Promise<IChecklist[]> {
    const response = await this.apiClient.protectedApi.get<IApplicationChecklistResponse>(
      `applications/${applicationId}/complianceChecklists`,
    );
    return response.data.complianceChecklists;
  }

  public async updateChecklistItem(
    applicationId: string,
    checklistId: string,
    checklistItemId: string,
    result: IAssessmentResult,
  ): Promise<boolean> {
    const response = await this.apiClient.protectedApi.patch<any>(
      `applications/${applicationId}/complianceChecklists/${checklistId}/checklistItems/${checklistItemId}`,
      result,
    );
    return response.status === 200;
  }
}
