import React from 'react';

import { styled, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout/PageLayout';
import MainLayout from 'components/MainLayout/MainLayout';
import AppSideNavigation from 'components/AppSideNavigation/AppSideNavigation';
import { AppSideNavigationTypes } from 'components/AppSideNavigation/AppSideNavigation.types';
import BrownContentBox from 'components/BrownContentBox/BrownContentBox';
import { useStoreDispatch } from 'store/hooks';
import { ApplicationTab } from 'models/Application.model';
import { fetchUserApplications } from 'store/domain-data/application/application';
import ApplicationDetailsSidePanel from 'components/ApplicaitonDetailsSidePanel/ApplicationDetailsSidePanel';
import DetailsPanel from 'components/DetailsPanel/DetailsPanel';
import ApplicationsList from 'components/ApplicationsList/ApplicationsList';

const ApplicationsPage = () => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [currentTab, setCurrentTab] = React.useState<ApplicationTab>(ApplicationTab.Unallocated);

  React.useEffect(() => {
    dispatch(fetchUserApplications());
  }, [dispatch]);

  const handleOnClickTab = React.useCallback((activeTab: ApplicationTab) => {
    setCurrentTab(activeTab);
  }, []);

  const tabs = React.useMemo(() => {
    // Tabs to be rendered based on the user roles in future
    return (
      <StyledTabList aria-label="applications tab list">
        <Tab
          label={t(ApplicationTab.Unallocated)}
          value={ApplicationTab.Unallocated}
          onClick={() => handleOnClickTab(ApplicationTab.Unallocated)}
        />
        <Tab
          label={t(ApplicationTab.Allocated)}
          value={ApplicationTab.Allocated}
          onClick={() => handleOnClickTab(ApplicationTab.Allocated)}
        />
        <Tab
          label={t(ApplicationTab.Determined)}
          value={ApplicationTab.Determined}
          onClick={() => handleOnClickTab(ApplicationTab.Determined)}
        />
        <Tab
          label={t(ApplicationTab.Withdrawn)}
          value={ApplicationTab.Withdrawn}
          onClick={() => handleOnClickTab(ApplicationTab.Withdrawn)}
        />
        <Tab
          label={t(ApplicationTab.TeamMembers)}
          value={ApplicationTab.TeamMembers}
          onClick={() => handleOnClickTab(ApplicationTab.TeamMembers)}
        />
      </StyledTabList>
    );
  }, [handleOnClickTab, t]);

  if (!tabs) {
    return null;
  }

  return (
    <MainLayout
      navigation={<AppSideNavigation activeMenuItemType={AppSideNavigationTypes.Applications} />}
      rightColumn={
        <DetailsPanel>
          <ApplicationDetailsSidePanel />
        </DetailsPanel>
      }
    >
      <PageLayout title={t('Applications')}>
        <StyledApplicationsPage>
          <BrownContentBox>
            <TabContext value={currentTab}>
              {tabs}
              <StyledTabPanel value={ApplicationTab.Unallocated}>
                <ApplicationsList type={ApplicationTab.Unallocated} />
                <ApplicationsList type={ApplicationTab.Allocated} />
                <ApplicationsList type={ApplicationTab.Determined} />
                <ApplicationsList type={ApplicationTab.Withdrawn} />
              </StyledTabPanel>
            </TabContext>
          </BrownContentBox>
        </StyledApplicationsPage>
      </PageLayout>
    </MainLayout>
  );
};

export default ApplicationsPage;

const StyledApplicationsPage = styled('main')(({ theme }) => ({}));

const StyledTabList = styled(TabList)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
  borderBottom: `${theme.palette.background.default} solid 3px`,
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: 0,
}));
