import React from 'react';
import { ButtonProps } from '@mui/material/Button/Button';
import { Button } from '@odl/core';
import { styled } from '@mui/material';

type Props = ButtonProps;

const ODLOutlinedButton: React.FC<Props> = ({ title, children, ...otherProps }) => {
  return (
    <StyledButton variant={'outlined'} aria-label={title} {...otherProps}>
      {title}
      {!title && children}
    </StyledButton>
  );
};

export default ODLOutlinedButton;

const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-outlinedPrimary:hover': {
    backgroundColor: theme.palette.objective.light.grey[400],
    border: `1px solid ${theme.palette.objective.light.grey[400]}`,
  },
  '&.MuiButton-outlinedPrimary:active': {
    backgroundColor: theme.palette.objective.light.background.hover,
  },
  '&.MuiButton-outlinedPrimary': {
    color: theme.palette.objective.light.text.secondary,
    border: `1px solid ${theme.palette.objective.light.grey[500]}`,
  },
  '&&.Mui-disabled': {
    background: theme.palette.objective.light.background.paper,
    color: theme.palette.objective.light.text.disabled,
    border: `1px solid ${theme.palette.objective.light.grey[500]}`,
  },
}));
