import { createAction, createReducer } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { createDeepEqualSelector } from 'store/utils';

// Types
type UIState = {
  isSideNavigationCollapsed: boolean;
  isDetailsPanelCollapsed: boolean;
};

// Actions
export const setIsSideNavigationCollapsed = createAction<boolean>('appState/ui/setIsSideNavigationCollapsed');
export const toggleIsSideNavigationCollapsed = createAction<void>('appState/ui/toggleIsSideNavigationCollapsed');

export const setIsDetailsPanelCollapsed = createAction<boolean>('appState/ui/setIsDetailsPanelCollapsed');
export const toggleIsDetailsPanelCollapsed = createAction<void>('appState/ui/toggleIsDetailsPanelCollapsed');

// Reducer

export const initialState: UIState = {
  isSideNavigationCollapsed: true,
  isDetailsPanelCollapsed: true,
};

export const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setIsSideNavigationCollapsed, (draft, action) => {
      draft.isSideNavigationCollapsed = action.payload;
    })
    .addCase(toggleIsSideNavigationCollapsed, (draft, action) => {
      draft.isSideNavigationCollapsed = !draft.isSideNavigationCollapsed;
    })
    .addCase(setIsDetailsPanelCollapsed, (draft, action) => {
      draft.isDetailsPanelCollapsed = action.payload;
    })
    .addCase(toggleIsDetailsPanelCollapsed, (draft, action) => {
      draft.isDetailsPanelCollapsed = !draft.isDetailsPanelCollapsed;
    });
});

// Selectors

const selectUIState = (state: RootState) => state.appState.ui;

export const selectIsSideNavigationCollapsed = createDeepEqualSelector([selectUIState], (state: UIState) => {
  return state.isSideNavigationCollapsed;
});

export const selectIsDetailsPanelCollapsed = createDeepEqualSelector([selectUIState], (state: UIState) => {
  return state.isDetailsPanelCollapsed;
});
