import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from '@reduxjs/toolkit';
import { AddUserFormValues, IUserEntity, UpdateUserFormValues, UpdateUserRolesFormValues } from 'models/User.model';
import { RootState } from 'store/types';
import { serviceContainer } from 'services/serviceContainer';
import { createDeepEqualSelector } from 'store/utils';
import { fetchRoles } from 'store/domain-data/role/role';

// Entity Adapter
const entityAdapter = createEntityAdapter<IUserEntity>();

// Actions
const upsertUsers = createAction<IUserEntity[]>('domainData/user/upsertUsers');
const upsertUser = createAction<IUserEntity>('domainData/user/upsertUser');

// Thunks
export const fetchUsers = createAsyncThunk('domainData/user/fetchUsers', async (_, thunkAPI) => {
  const userService = serviceContainer.cradle.userService;
  const { users } = await userService.fetchUsers();
  thunkAPI.dispatch(upsertUsers(users));
  return users;
});

export const fetchUserById = createAsyncThunk('domainData/user/fetchUser', async (userId: string, thunkAPI) => {
  const userService = serviceContainer.cradle.userService;
  const { user } = await userService.fetchUserById(userId);
  thunkAPI.dispatch(upsertUser(user));
  return user;
});

export const addNewUser = createAsyncThunk('domainData/user/updateUser', async (args: AddUserFormValues, thunkAPI) => {
  const userService = serviceContainer.cradle.userService;
  const { id } = await userService.addNewUser(args);
  thunkAPI.dispatch(fetchUserById(id));
  return { id };
});

export const updateUser = createAsyncThunk(
  'domainData/user/updateUser',
  async (args: UpdateUserFormValues, thunkAPI) => {
    const userService = serviceContainer.cradle.userService;
    const user = await userService.updateUser(args);
    thunkAPI.dispatch(upsertUser(user));
    return user;
  },
);

export const updateUserRoles = createAsyncThunk(
  'domainData/user/updateUserRoles',
  async (args: UpdateUserRolesFormValues, thunkAPI) => {
    const userService = serviceContainer.cradle.userService;
    const user = await userService.updateUserRoles(args);
    thunkAPI.dispatch(upsertUser(user));
    thunkAPI.dispatch(fetchRoles());
    thunkAPI.dispatch(fetchUsers());
    return user;
  },
);

// Reducer
const initialState = entityAdapter.getInitialState();

export const userReducer = createReducer<typeof initialState>(initialState, (builder) => {
  builder.addCase(upsertUsers, entityAdapter.upsertMany).addCase(upsertUser, entityAdapter.upsertOne);
});

// Selectors
export const {
  selectById: selectUserEntityById,
  selectIds: selectUserEntityIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUserEntities,
  selectTotal: selectTotalUserEntities,
} = entityAdapter.getSelectors((state: RootState) => state.domainData.user);

export const selectUserRoleIds = createDeepEqualSelector([selectUserEntityById], (user) => {
  if (!user) {
    return [];
  }
  return user.roles;
});

export const selectUserByEmailAddress = createDeepEqualSelector(
  [selectAllUserEntities, (state: RootState, emailAddress: string) => emailAddress],
  (users, emailAddress) => {
    return users.find((user) => user.emailAddress === emailAddress);
  },
);
