import React from 'react';
import { styled } from '@mui/material';

type Props = {
  children: React.ReactNode;
};
const BrownContentBox: React.FC<Props> = ({ children, ...otherProps }) => {
  return <StyledBackgroundBox {...otherProps}>{children}</StyledBackgroundBox>;
};

export default BrownContentBox;

const StyledBackgroundBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.objective.light.background.default,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
}));
