import React, { Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { useStoreSelector } from 'store/hooks';
import { selectAuthenticationState } from 'store/app-state/authentication/authentication';

import AuthenticationPage from 'components/AuthUI/AuthenticationPage';

import LoginScreen from 'components/AuthUI/LoginScreen';
import NewAccount from 'components/AuthUI/NewAccount';
import ResetPassword from 'components/AuthUI/ResetPassword';

import ApplicationsPage from 'pages/applications/ApplicationsPage';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import Spacer from 'components/Spacer/Spacer';
import { useTranslation } from 'react-i18next';
import {
  USE_CREATE_APPLICATION,
  USE_DASHBOARD,
  USE_REPORTING,
  USE_TASKS_AND_SCHEDULING,
  USE_TEAMS,
  USE_USERS_AND_PERMISSIONS,
} from 'constants/configs';

// Route-based code splitting
const AllApplicationsPageFactory = import('pages/all-applications/AllApplicationsPage');
const AllApplicationsPage = React.lazy(() => AllApplicationsPageFactory);

const DashboardPageFactory = import('pages/dashboard/DashboardPage');
const DashboardPage = React.lazy(() => DashboardPageFactory);

const CreateApplicationPageFactory = import('pages/create-application/CreateApplicationPage');
const CreateApplicationPage = React.lazy(() => CreateApplicationPageFactory);

const ApplicationDetailsPageFactory = import('pages/application-details/ApplicationDetailsPage');
const ApplicationDetailsPage = React.lazy(() => ApplicationDetailsPageFactory);

const EditApplicationPageFactory = import('pages/edit-application/EditApplicationPage');
const EditApplicationPage = React.lazy(() => EditApplicationPageFactory);

const ComplianceChecklistPageFactory = import('pages/compliance-checklist/ComplianceChecklistPage');
const ComplianceChecklistPage = React.lazy(() => ComplianceChecklistPageFactory);

const TasksAndSchedulingPageFactory = import('pages/tasks-and-scheduling/TaskAndSchedulingPage');
const TasksAndSchedulingPage = React.lazy(() => TasksAndSchedulingPageFactory);

const ReportingPageFactory = import('pages/reporting/ReportingPage');
const ReportingPage = React.lazy(() => ReportingPageFactory);

const TeamsPageFactory = import('pages/teams/TeamsPage');
const TeamsPage = React.lazy(() => TeamsPageFactory);

const UsersAndPermissionsPageFactory = import('pages/users-and-permissions/UsersAndPermissionsPage');
const UsersAndPermissionsPage = React.lazy(() => UsersAndPermissionsPageFactory);

const UsersAndPermissionsDetailsPageFactory = import(
  'pages/users-and-permissions-details/UsersAndPermissionsDetailsPage'
);
const UsersAndPermissionsDetailsPage = React.lazy(() => UsersAndPermissionsDetailsPageFactory);

const UsersPageFactory = import('pages/users-page/UsersPage');
const UsersPage = React.lazy(() => UsersPageFactory);

const UsersListFactory = import('pages/users-page/UsersList');
const UsersList = React.lazy(() => UsersListFactory);

const SignOutPageFactory = import('pages/sign-out/SignOutPage');
const SignOutPage = React.lazy(() => SignOutPageFactory);

const RouteController = () => {
  const { authenticated } = useStoreSelector(selectAuthenticationState);
  const { t } = useTranslation();

  return (
    <Router>
      <Suspense
        fallback={
          <Backdrop open={true}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <CircularProgress size={100} thickness={4} disableShrink />
              <Spacer y={4} />
              {t('Loading...')}
            </Box>
          </Backdrop>
        }
      >
        {!authenticated && (
          <Routes>
            <Route
              path="/"
              element={
                <AuthenticationPage>
                  <LoginScreen />
                </AuthenticationPage>
              }
            />
            <Route
              path="new-account"
              element={
                <AuthenticationPage>
                  <NewAccount />
                </AuthenticationPage>
              }
            />
            <Route
              path="reset"
              element={
                <AuthenticationPage>
                  <ResetPassword />
                </AuthenticationPage>
              }
            />
            <Route path="signedout" element={<SignOutPage />} />
            <Route
              path="/*"
              element={
                <AuthenticationPage>
                  <LoginScreen />
                </AuthenticationPage>
              }
            />
          </Routes>
        )}

        {authenticated && (
          <Routes>
            {USE_DASHBOARD && <Route path="/" element={<DashboardPage />} />}
            <Route path={USE_DASHBOARD ? 'applications' : '/'} element={<ApplicationsPage />} />
            <Route path="all-applications" element={<AllApplicationsPage />} />
            {USE_CREATE_APPLICATION && <Route path="create-application" element={<CreateApplicationPage />} />}
            <Route path="application-details/:applicationId/edit" element={<EditApplicationPage />} />
            <Route path="application-details/:applicationId" element={<ApplicationDetailsPage />} />
            <Route
              path="application-details/:applicationId/compliance-check-list"
              element={<ComplianceChecklistPage />}
            />

            {USE_TASKS_AND_SCHEDULING && <Route path="tasks-and-scheduling" element={<TasksAndSchedulingPage />} />}

            {USE_REPORTING && <Route path="reporting" element={<ReportingPage />} />}

            {USE_TEAMS && <Route path="teams" element={<TeamsPage />} />}

            {USE_USERS_AND_PERMISSIONS && (
              <React.Fragment>
                <Route path="users-and-permissions" element={<UsersAndPermissionsPage />} />
                <Route path="users-and-permissions/add" element={<UsersAndPermissionsDetailsPage />} />
                <Route path="users-and-permissions/:roleId" element={<UsersAndPermissionsDetailsPage />} />
                <Route path="users-and-permissions/users" element={<UsersPage />}>
                  <Route path=":userId" element={<UsersList />} />
                </Route>
              </React.Fragment>
            )}
            <Route path={'*'} element={<Navigate to={'/'} />} />
          </Routes>
        )}
      </Suspense>
    </Router>
  );
};

export default RouteController;
