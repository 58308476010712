import React from 'react';
import { useStoreSelector } from 'store/hooks';
import { selectApplicationEntityById } from 'store/domain-data/application/application';
import ODLChip from 'components/ODL/ODLChip/ODLChip';
import { useTranslation } from 'react-i18next';
import { ComplianceStatus } from 'models/Compliance.model';

type Props = {
  applicationId: string;
};

const ApplicationComplianceChip: React.FC<Props> = ({ applicationId }) => {
  const { t } = useTranslation();
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  const complianceLabel = React.useMemo(() => {
    return t('To Do');
  }, [t]);

  if (!application || application.complianceChecklists?.summaryStatus === ComplianceStatus.NotStarted) {
    return t('To Do');
  }

  return <ODLChip label={complianceLabel} />;
};

export default ApplicationComplianceChip;
