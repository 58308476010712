import React from 'react';
import { styled, TableRow, TableRowProps } from '@mui/material';

type Props = TableRowProps & {
  children: React.ReactNode;
};

const ODLTableRow = React.forwardRef<HTMLTableRowElement, React.PropsWithChildren<Props>>(
  ({ children, ...otherProps }, ref) => {
    return (
      <StyledTableRow ref={ref} {...otherProps}>
        {children}
      </StyledTableRow>
    );
  },
);

export default ODLTableRow;

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));
